import { createTheme } from '@mui/material/styles';
import { enUS, esES, frFR, itIT, plPL, ptPT, deDE, ruRU, csCZ, hrHR } from '@mui/material/locale';

const locales = {
  en: enUS,
  es: esES,
  fr: frFR,
  it: itIT,
  pl: plPL,
  pt: ptPT,
  de: deDE,
  cs: csCZ,
  ru: ruRU,
  sl: enUS,
  hr: hrHR,
};

export type ThemeSupportedLocales = keyof typeof locales;

declare module '@mui/material/styles' {

  // Palette: custom variant types
  interface Palette {
    brand: Palette['primary'];
    gray: Palette['primary'];
  }
  interface PaletteOptions {
    brand: PaletteOptions['primary'];
    gray: PaletteOptions['primary'];
  }

  // Typography: custom variants types (https://mui.com/customization/typography/#adding-amp-disabling-variants)
  interface TypographyVariants {
    display: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    display?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    display: true;
  }
}

// Create a theme instance.
// Docs: https://mui.com/customization/theming/
// Theme defaults: https://mui.com/customization/default-theme/
// Theme creator tool: https://bareynol.github.io/mui-theme-creator/
let theme = createTheme({
  // https://mui.com/customization/typography/#font-family
  typography: {
    fontFamily: 'Inter, sans-serif',
    // fontWeightLight: 400,
    // fontWeightRegular: 400,
    // fontWeightMedium: 700,
    // fontWeightBold: 700,
    h1: {
      fontWeight: 700,
    },
  },

  // https://mui.com/customization/spacing/
  spacing: 8,

  // https://mui.com/customization/breakpoints/
  breakpoints: {
    values: {
      xs: 0, // default: 0
      sm: 600, // default: 600
      md: 900, // default: 900
      lg: 1158, // default: 1200
      xl: 1400, // default: 1536
    },
  },

  // https://mui.com/customization/palette/
  palette: {
    // override default colors
    primary: {
      light: '#F7931E',
      main: '#FF5C04',
      dark: '#D44A00',
    },
    secondary: {
      main: '#1288CF',
    },
    error: {
      main: '#F43319',
      dark: '#D12D17',
    },
    warning: {
      main: '#FFCC00',
      dark: '#E4B80B',
    },
    success: {
      main: '#2DCB48',
      dark: '#28B440',
    },
    // create custom colors
    brand: {
      light: '#343D4E',
      main: '#182641',
      dark: '#141C2F',
    },
    gray: {
      main: '#F7F7F7',
    }
  },
});

// https://mui.com/customization/typography/#font-size
theme = createTheme(theme, {
  typography: {
    display: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(51),
      lineHeight: theme.typography.pxToRem(54),
      letterSpacing: theme.typography.pxToRem(0),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(42),
        lineHeight: theme.typography.pxToRem(45),
      },
    },
    h1: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(40),
      lineHeight: theme.typography.pxToRem(46),
      letterSpacing: theme.typography.pxToRem(-0.5),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(38),
        lineHeight: theme.typography.pxToRem(40),
      },
    },
    h2: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(34),
      lineHeight: theme.typography.pxToRem(36),
      letterSpacing: theme.typography.pxToRem(-0.23),
    },
    h3: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(26),
      letterSpacing: theme.typography.pxToRem(-0.1),
    },
    h4: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(25),
      letterSpacing: theme.typography.pxToRem(0),
    },
    h5: {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      letterSpacing: theme.typography.pxToRem(0.1),
    },
    h6: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(17),
      letterSpacing: theme.typography.pxToRem(0),
    },
    subtitle1: {
      fontSize: theme.typography.pxToRem(22),
      lineHeight: theme.typography.pxToRem(28),
      letterSpacing: theme.typography.pxToRem(-0.31),
    },
    subtitle2: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(28),
      letterSpacing: theme.typography.pxToRem(0),
    },
    body1: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      letterSpacing: theme.typography.pxToRem(0.1),
    },
    body2: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      letterSpacing: theme.typography.pxToRem(0),
    },
    button: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      letterSpacing: theme.typography.pxToRem(0),
      fontWeight: 'bold',
      textAlign: 'center',
      textTransform: 'none',
    },
    overline: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(21),
      letterSpacing: theme.typography.pxToRem(0),
    },
  },
});

theme = createTheme(theme, {
  // https://mui.com/customization/theme-components/
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
          },
        },
      }
    },
    MuiGrid: {
      defaultProps: {
        columnSpacing: 4,
        rowSpacing: 3,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontSize: theme.typography.button.fontSize,
          lineHeight: theme.typography.button.lineHeight,
          letterSpacing: theme.typography.pxToRem(0),
          fontWeight: 'bold',
          minWidth: 145,
          borderRadius: 5,
        },
        sizeSmall: {
          paddingTop: 7,
          paddingBottom: 7,
        },
        sizeMedium: {
          paddingTop: 11,
          paddingBottom: 11,
        },
        sizeLarge: {
          paddingTop: 16,
          paddingBottom: 16,
        },
      },
    },
  },
});

export default theme;

export const useLocalizedTheme = (locale?: String) => {
  let themeLocale = locale ? locale : process.env.NEXT_PUBLIC_DEFAULT_LOCALE;
  return createTheme(theme, locales[themeLocale as ThemeSupportedLocales]);
}
